import React from "react";
import { Layout } from "../components/Layout";
import { graphql, PageProps } from "gatsby";
import { Seo } from "../components/Seo";
import {
  MdxBlogPosts,
  MdxBlogPostsData,
} from "../components/Blog/MdxBlogPosts";
import { PagePagination } from "../components/PagePagination";
import { MdxPaginatedPageContext } from "../types/mdx";
import { Container } from "../components/Section";
import { PageHeader, PageTitle } from "../components/PageHeader";

type PageData = {
  allMdx: MdxBlogPostsData;
};

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMdx(
      filter: { fields: { collection: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      ...BlogPostsData
    }
  }
`;

const Page: React.FC<PageProps<PageData, MdxPaginatedPageContext>> = ({
  data,
  pageContext,
}) => {
  return (
    <Layout>
      <Seo
        ogFullTitle
        title="Our Blog"
        description="Learn how your business can benefit from the latest trends in web development."
      />
      <Container>
        <PageHeader>
          <PageTitle>Our Blog</PageTitle>
        </PageHeader>
        <MdxBlogPosts blogPostsData={data.allMdx} />
        <PagePagination {...pageContext} />
      </Container>
    </Layout>
  );
};

export default Page;
