import React from "react";
import { Link } from "./Link";
import * as styles from "./NextPrev.module.scss";
import { Divider } from "./Divider";

type NextPrevItem = {
  to: string;
  title: string;
};

type Props = {
  next?: NextPrevItem;
  prev?: NextPrevItem;
};

export const NextPrev: React.FC<Props> = ({ next, prev }) => {
  if (!next && !prev) return null;

  return (
    <div>
      <Divider />

      <ul className={styles.list}>
        <li>
          {prev && (
            <Link to={prev.to} rel="prev">
              {prev.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.to} rel="next">
              {next.title}
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
};
