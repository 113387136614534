import React from "react";
import { NextPrev } from "./NextPrev";

type Props = {
  previousPagePath?: string;
  nextPagePath?: string;
};

export const PagePagination: React.FC<Props> = ({
  previousPagePath,
  nextPagePath,
}) => {
  return (
    <NextPrev
      prev={
        previousPagePath
          ? { to: previousPagePath, title: "Previous Page" }
          : undefined
      }
      next={nextPagePath ? { to: nextPagePath, title: "Next Page" } : undefined}
    />
  );
};
